import React, { useEffect } from "react";
import { navigate } from "gatsby";

// TO DO
// detect path entered. Say this artist name isn't registered yet. Do you want to register as <artist>?

export default function NotFoundPage() {
  useEffect(() => {
    navigate("/"); // redirecting to home page
  }, []);

  return (
    <div>
      {/* <h1>(404) NotFound Page</h1> */} 

      
    </div>
  );
}